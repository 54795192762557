import httpClient from '@/lib/client/httpClient';

import {
  AuthResponse,
  ConfirmtMethod,
  RegisterMethod,
  ResetPasswordForm,
  SignInForm,
  SignInMethod,
  SignUpForm,
  ConfirmForm,
  ResetPasswordMethod,
  CreateNewPasswordMethod,
  CreateNewPasswordForm,
  LogooutMethod,
  ResetOtpCodeMethodType,
  ResesOtpCodeForm,
} from './../types.d';

import { UserType } from '@/lib/store/user.store';

export const signUp: RegisterMethod = async form => {
  return httpClient.post<SignUpForm, AuthResponse>('/user/register', form);
};

export const signIn: SignInMethod = async form => {
  return httpClient.post<
    SignInForm,
    AuthResponse<
      UserType & { access_token: string; 'x-access-resource': string }
    >
  >('/user/login', form);
};

export const activateAccount: ConfirmtMethod = async form => {
  return httpClient.post<ConfirmForm, AuthResponse>('/activate-profile', form);
};

export const resetPassword: ResetPasswordMethod = async form => {
  return httpClient.post<ResetPasswordForm, AuthResponse>(
    '/password-reset',
    form,
  );
};

export const createNewPassword: CreateNewPasswordMethod = async form => {
  return httpClient.post<CreateNewPasswordForm, AuthResponse>(
    '/password-reset-confirm',
    form,
  );
};

export const resetOtpCode: ResetOtpCodeMethodType = async form => {
  return httpClient.post<ResesOtpCodeForm, AuthResponse>('/reset-otp', form);
};

export const logout: LogooutMethod = async () => {
  return httpClient.get<undefined, void>('/user/logout');
};

const methods = {
  signUp,
  signIn,
  activateAccount,
  resetPassword,
  resetOtpCode,
  createNewPassword,
  logout,
};

export default methods;
