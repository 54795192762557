import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type UserType = {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  role: 'user' | 'company_manager';
  job_title?: string;
  company_name?: string;
  status: 'active' | 'inactive';
};

export type StoreType = {
  user: UserType | null;
  isAuth: boolean;
};

export type UserTypeActions = {
  setAuth: (isAuth: boolean) => void;
  setUser: (user: UserType | null) => void;
  removeUser: () => void;
  updateUser: (key: keyof UserType, value: UserType[keyof UserType]) => void;
};

export type UserStore = StoreType & UserTypeActions;

export const useUserStore = create<UserStore>()(
  persist(
    set => ({
      user: null,
      isAuth: false,
      setAuth: isAuth => set({ isAuth }),
      setUser: user => set({ user }),
      removeUser: () => set({ user: null }),
      updateUser: (key, value) =>
        set(state => ({ user: { ...state.user, [key]: value } as UserType })),
    }),
    { name: 'user-store', skipHydration: false },
  ),
);
