import { colors } from '@/lib/theme/palette';
import ButtonComponent from '@/shared/components/ButtonComponent';
import Icon from '@/shared/components/Icon';
import SearchBar from '@/shared/components/SearchBar';
import { Box } from '@mui/system';
import React from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
};

function SearchMenu({ open, onClose }: Props) {
  const hideSearchMenu = () => {
    onClose();
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'fixed',
          width: '100%',
          height: '76px',
          top: 0,
          left: 0,
          px: '20px',
          backgroundColor: colors.white,
          zIndex: 101,
          transform: open ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'transform 0.2s ease-in-out',
        }}
      >
        <ButtonComponent
          type="button"
          aria-label="search"
          variant="outlined-primary"
          sx={{
            borderRadius: '50%',
            border: '1px solid #C9D3FF',
            width: {
              xs: '36px',
              xl: '48px',
            },
            height: {
              xs: '36px',
              xl: '48px',
            },
          }}
        >
          <Icon
            icon="search"
            color={colors.blue}
            sx={{
              width: {
                xs: '16px',
                xl: '28px',
              },
              height: {
                xs: '16px',
                xl: '28px',
              },
            }}
          />
        </ButtonComponent>
        <SearchBar variant="mobile" onHideMenu={hideSearchMenu} />

        <ButtonComponent
          height={'32px'}
          width={'32px'}
          variant="outlined-secondary"
          sx={{
            border: 'none',
          }}
          onClick={hideSearchMenu}
        >
          <Icon icon={'close'} width={'32px'} height={'32px'} />
        </ButtonComponent>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 99,
          display: open ? 'block' : 'none',
        }}
        onClick={hideSearchMenu}
      />
    </>
  );
}

export default SearchMenu;
