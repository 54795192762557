'use client';
import React, { useState } from 'react';
import { Box, Typography, Link, Stack } from '@mui/material';

import OtpInput from 'react-otp-input';

import { colors } from '@/lib/theme/palette';

import Button from '@/shared/components/ButtonComponent';

import {
  AuthType,
  ConfirmForm,
  onSubmitAuthFormType,
  ResetPasswordForm,
} from '../types';

import './index.css';

type Props = {
  title: string;
  email: string;
  backToAuthType: AuthType;
  loading: boolean;
  onSubmit: onSubmitAuthFormType<ConfirmForm | ResetPasswordForm>;
  setAuthType: (authType: AuthType) => void;
};

export default function Confirm(props: Props) {
  const { title, loading, email, onSubmit, setAuthType, backToAuthType } =
    props;

  const [form, setForm] = useState<ConfirmForm>({
    email,
    otp_code: '',
  });

  const handleChange = (value: string) => {
    setForm({
      ...form,
      otp_code: value,
    });
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    onSubmit(form);
  };

  const handleResend = () => {
    onSubmit({ email }, 'resend');
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        {title}
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={1}
      >
        We have sent you a confirmation code to {email}
        <br />
        Enter code below to proceed
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <OtpInput
            shouldAutoFocus
            containerStyle="containerStyle"
            inputStyle="inputStyle"
            value={form.otp_code}
            inputType="tel"
            onChange={handleChange}
            numInputs={4}
            renderInput={props => <input required {...props} />}
          />
        </Box>

        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Confirm request
        </Button>
      </Box>

      <Button
        variant="outlined-primary"
        disabled={loading}
        loading={loading}
        sx={{ marginTop: 1 }}
        onClick={() => setAuthType(backToAuthType)}
      >
        Back to website
      </Button>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="8px"
        sx={{
          mt: '32px',
        }}
      >
        <Typography variant="body2">Didn’t receive the email?</Typography>

        <Link
          component="button"
          variant="body2"
          sx={{
            color: colors.blue,
            fontWeight: 700,
            textDecoration: 'none',
          }}
          onClick={handleResend}
        >
          Click to resend
        </Link>
      </Stack>
    </Box>
  );
}
