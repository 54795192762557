'use client';

import { useEffect, useState } from 'react';
import { useRouter, usePathname } from 'next/navigation';

import { Container, Box, useMediaQuery } from '@mui/material';

import httpClient from '@/lib/client/httpClient';
import theme from '@/lib/theme/theme';
import { colors } from '@/lib/theme/palette';

import { useUserStore } from '@/lib/store/user.store';
import { useNotifyStore } from '@/lib/store/notify.store';

import Logo from '@/shared/components/LogoComponent';
import GeneralModal from '@/shared/components/GeneralModal';
import SearchBar from '@/shared/components/SearchBar';
import Auth from '@/shared/features/Auth';

import AuthDataApi from '@/shared/features/Auth/api/auth.api';

import {
  AuthType,
  ConfirmForm,
  CreateNewPasswordForm,
  GeneralForm,
  onSubmitAuthFormType,
  ResesOtpCodeForm,
  ResetPasswordForm,
  SignInForm,
  SignUpForm,
} from '@/shared/features/Auth/types';

import useQueryParams from '@/shared/hooks/useQueryParams.hook';
import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';

import HeaderButtons from './components/HeaderButtons';
import BurgerMenu from './components/BurgerMenu';
import SearchMenu from './components/SearchMenu';

const Header = () => {
  const router = useRouter();
  const route = usePathname();

  const isMiddleScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isHomePage = route === '/';

  const { isAuth, removeUser, setAuth, setUser, user } = useUserStore(
    state => state,
  );

  const { setNotify } = useNotifyStore(state => state);

  const { queries, setQueries } = useQueryParams<{ email: string }>({
    email: '',
  });

  const [otpCode, setOtpCode] = useState<string>('');
  const [fixedHeader, setFixedHeader] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const [openSearchMenu, setOpenSearchMenu] = useState(false);

  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [authType, setAuthType] = useState<AuthType>('signIn');

  const handleCloseModal = () => {
    setOpenModal(false);
    setQueries({ email: '' });
    setAuthType('signIn');
  };

  const handleOpenModal = () => {
    if (isAuth) {
      return;
    }
    setOpenModal(true);
  };

  const handleOpenSingUpModal = () => {
    setAuthType('signUp');
    handleOpenModal();
  };

  const handleToggleMenu = () => {
    setOpenBurgerMenu(!openBurgerMenu);
  };

  const handleCloseBurgerMenu = () => {
    setOpenBurgerMenu(false);
    setOpenSearchMenu(false);
  };

  const handleOpenSearchMenu = () => {
    setOpenSearchMenu(true);
  };

  const handleCloseSearchMenu = () => {
    setOpenSearchMenu(false);
    setOpenBurgerMenu(false);
  };

  const handleLogout = async () => {
    try {
      await AuthDataApi.logout();

      httpClient.removeXAccessToken();
      httpClient.removeXAcessResource();

      removeUser();
      setAuth(false);

      if (window.location.pathname.includes('/profile')) {
        router.push('/');
      }

      setOpenModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitAuthForm: onSubmitAuthFormType<
    GeneralForm,
    Promise<void>
  > = async (form, trigger) => {
    setFormLoading(true);

    try {
      switch (authType) {
        case 'signUp':
          await handleCreateAccount(form);
          break;
        case 'signIn':
          await handleSignIn(form);
          break;
        case 'resetPassword':
          await handleResetPassword(form);
          break;
        case 'setNewPassword':
          await handleSetNewPassword({
            ...form,
            email: queries.email,
            otp_code: otpCode,
          });
          break;
        case 'signUpConfirmation':
          if (trigger === 'resend') {
            await handleResendOtpCode({
              action: 'profile_activation',
              email: queries.email,
            });
            break;
          }

          await handleConfirmOtpCode({ ...form, email: queries.email });

          setAuthType('signIn');
          break;
        case 'resetConfirmation':
          if (trigger === 'resend') {
            await handleResetPassword(form);
            break;
          }

          setOtpCode(form.otp_code);

          await handleConfirmOtpCode({
            ...form,
            email: queries.email,
            reset_check: true,
          });

          setAuthType('setNewPassword');
          break;
        case 'EmailAlreadyRegistrated':
          await handleResendOtpCode({
            action: 'profile_activation',
            email: queries.email,
          });

          setAuthType('signUpConfirmation');
          break;
        case 'ActiveYourAccount':
          await handleResendOtpCode({
            action: 'profile_activation',
            email: queries.email,
          });

          setAuthType('signUpConfirmation');
          break;
        case 'emailNotFound':
          break;
        default:
          break;
      }
    } catch (error) {
      if (authType === 'signIn') {
        setNotify({
          type: 'error',
          message: 'Invalid email or password',
        });

        return;
      }

      checkErrorMessageHelperSnippet(error as Error, setNotify);

      if (error instanceof Error) {
        const errorData = JSON.parse(error.message);

        if (errorData.detail.non_field_error === 'Email already registered.') {
          setQueries({ email: form.email });
          setAuthType('EmailAlreadyRegistrated');
        }

        if (errorData.detail.non_field_error === 'User does not exist.') {
          setAuthType('emailNotFound');
          setQueries({ email: form.email });
        }

        if (
          errorData.detail.non_field_error === 'Please activate your account.'
        ) {
          setQueries({ email: form.email });
          setAuthType('ActiveYourAccount');
        }
      }
    } finally {
      setFormLoading(false);
    }
  };

  async function handleCreateAccount(form: GeneralForm): Promise<void> {
    await AuthDataApi.signUp(form as SignUpForm);

    setQueries({ email: form.email });

    setAuthType('signUpConfirmation');
  }

  async function handleSignIn(form: GeneralForm): Promise<void> {
    const { data } = await AuthDataApi.signIn(form as SignInForm);

    httpClient.setXAccessToken(data.access_token);
    httpClient.setXAcessResource(data['x-access-resource']);

    setUser({
      ...data,
    });

    setAuth(true);

    setNotify({ type: 'success', message: 'You have successfully logged in' });

    handleCloseModal();
  }

  async function handleResendOtpCode(form: ResesOtpCodeForm): Promise<void> {
    await AuthDataApi.resetOtpCode(form);

    setNotify({ type: 'success', message: 'Otp code has been sent' });
  }

  async function handleResetPassword(form: GeneralForm): Promise<void> {
    await AuthDataApi.resetPassword(form as ResetPasswordForm);

    setAuthType('resetConfirmation');

    setQueries({ email: form.email });
  }

  async function handleSetNewPassword(
    form: CreateNewPasswordForm,
  ): Promise<void> {
    const { data } = await AuthDataApi.createNewPassword(form);

    setAuthType('signIn');

    setNotify({ type: 'success', message: data.message });
  }

  async function handleConfirmOtpCode(form: GeneralForm): Promise<void> {
    await AuthDataApi.activateAccount(form as ConfirmForm);
  }

  const onScrollDocument = () => {
    if (window.scrollY > 100) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window?.addEventListener('scroll', onScrollDocument);
  }, []);

  return (
    <>
      <Box
        component="header"
        boxSizing="border-box"
        position={fixedHeader || openBurgerMenu ? 'fixed' : 'absolute'}
        bgcolor={fixedHeader || openBurgerMenu ? colors.white : 'transparent'}
        boxShadow={
          fixedHeader || openBurgerMenu
            ? '0px 4px 4px rgba(0, 0, 0, 0.05)'
            : 'none'
        }
        padding={{
          xs: `20px 24px ${fixedHeader || openBurgerMenu ? '20px' : '0'}`,
          sm: `20px 7% ${fixedHeader || openBurgerMenu ? '20px' : '0'}`,
          md: `20px 7% ${fixedHeader ? '20px' : '0'}`,
          lg: `20px 8% ${fixedHeader ? '20px' : '0'}`,
          xl: `20px 8% ${fixedHeader ? '20px' : '0'}`,
        }}
        sx={{
          width: '100%',
          zIndex: 100,
          top: 0,
        }}
      >
        <Container disableGutters>
          <Box display="flex" alignItems="center">
            <Box>
              <Logo />
            </Box>

            {isMiddleScreen && !isHomePage && <SearchBar variant="header" />}

            <HeaderButtons
              isAuth={isAuth}
              user={user}
              onOpenSearchMenu={handleOpenSearchMenu}
              openBurgerMenu={openBurgerMenu}
              onOpenModal={handleOpenModal}
              onToggleMenu={handleToggleMenu}
              onLogout={handleLogout}
            />
          </Box>
        </Container>

        <GeneralModal
          open={openModal}
          handleClose={handleCloseModal}
          sx={{ backgroundColor: '#F7F9FF' }}
        >
          <Auth
            loading={formLoading}
            setAuthType={setAuthType}
            authType={authType}
            email={queries.email}
            handleClose={handleCloseModal}
            onSubmit={onSubmitAuthForm}
          />
        </GeneralModal>
      </Box>

      {isTabletScreen ? (
        <>
          <BurgerMenu
            open={openBurgerMenu}
            isAuth={isAuth}
            onOpenLoginModal={handleOpenModal}
            onOpenSingUpModal={handleOpenSingUpModal}
            onClose={handleCloseBurgerMenu}
            onLogout={handleLogout}
          />

          <SearchMenu open={openSearchMenu} onClose={handleCloseSearchMenu} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
