'use client';
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import InputField from '@/shared/components/InputField';
import Button from '../../../components/ButtonComponent';

import { AuthType, onSubmitAuthFormType, ResetPasswordForm } from '../types';

type Props = {
  loading: boolean;
  onSubmit: onSubmitAuthFormType<ResetPasswordForm>;
  setAuthType: (authType: AuthType) => void;
};

const ResetPassword = (props: Props) => {
  const { loading, onSubmit } = props;

  const [form, setForm] = useState<ResetPasswordForm>({
    email: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    onSubmit(form);
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        Reset password
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={1}
      >
        Your email
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <InputField
            label="Email"
            value={form.email}
            variant="filled"
            name="email"
            required
            disabled={loading}
            type="email"
            onChange={handleChange}
          />
        </Box>

        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Submit
        </Button>
      </Box>

      {/* <Button
        variant="outlined-primary"
        disabled={loading}
        loading={loading}
        sx={{ marginTop: 1 }}
        onClick={() => setAuthType('signIn')}
      >
        Back to Log in
      </Button> */}
    </Box>
  );
};

export default ResetPassword;
