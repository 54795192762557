import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter, useSearchParams } from 'next/navigation';

type QueryParams<T> = Record<
  keyof T,
  string | number | boolean | null | undefined
>;

export function serializeFormQuery<T>(params: QueryParams<T>): string {
  return Object.entries(params)
    .filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([key, value]) =>
        value != null &&
        value !== '' &&
        value !== undefined &&
        value !== false &&
        value !== 0,
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    )
    .join('&');
}

type SetQueryParams<T> = (params: T) => void;

function useQueryParams<T>(
  initialParams: T,
  options?: AppRouterInstance['push']['arguments'],
): {
  queries: T;
  setQueries: SetQueryParams<T>;
} {
  const router = useRouter();
  const searchParams = useSearchParams();

  new URLSearchParams(searchParams).forEach((value, key) => {
    initialParams[key as keyof T] = value as T[keyof T];
  });

  const setQueryParams: SetQueryParams<T> = params => {
    const queryString = serializeFormQuery(params as QueryParams<T>);

    router.push(`?${queryString}`, options);
  };

  const setQueries = (newParams: T) => {
    setQueryParams(newParams);
  };

  return { queries: initialParams, setQueries };
}

export default useQueryParams;
