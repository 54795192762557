import { NotifyType } from '@/lib/store/notify.store';

const checkErrorMessageHelperSnippet = (
  error: Error,
  callback: (errorMessage: NotifyType) => void,
) => {
  if (error instanceof Error) {
    try {
      const errorData = JSON.parse(error.message);

      let message: string = 'Went wrong, please try again later.';

      if (errorData.error && typeof errorData.error === 'string') {
        message = errorData.error;
      }
      if (errorData.detail && typeof errorData.detail === 'string') {
        message = errorData.detail;
      }
      if (
        errorData.detail &&
        typeof errorData.detail === 'object' &&
        Object.keys(errorData.detail).length > 0
      ) {
        message = errorData.detail[Object.keys(errorData.detail)[0]];
      }

      callback({
        type: 'error',
        message,
      });
    } catch (parseError) {
      callback({
        type: 'error',
        message: 'Failed to parse error data: ' + parseError,
      });
    }
  }
};

export default checkErrorMessageHelperSnippet;
