'use client';
import React, { useState } from 'react';

import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
} from '@mui/material';

import { colors } from '@/lib/theme/palette';

import InputField from '@/shared/components/InputField';
import Button from '@/shared/components/ButtonComponent';
// import Icon from '@/shared/components/Icon';

import { AuthType, onSubmitAuthFormType, SignInForm } from '../types';
import useValidate, {
  Rules,
  emailPattern,
} from '@/shared/hooks/useValidate.hook';
import mappingErrors from '@/shared/helpers/mappingErrors.helper';

type Props = {
  loading: boolean;
  onSubmit: onSubmitAuthFormType<SignInForm>;
  setAuthType: (authType: AuthType) => void;
};

const SignUp = (props: Props) => {
  const { loading, setAuthType, onSubmit } = props;

  const [form, setForm] = useState<SignInForm>({
    email: '',
    password: '',
    rememberMe: false,
  });

  const rules: Rules<SignInForm> = {
    email: {
      required: true,
      emailPattern,
      fieldKey: 'Email',
    },
    password: {
      required: true,
      fieldKey: 'Password',
    },

    rememberMe: {
      required: false,
    },
  };

  const { dirty, touch, errors } = useValidate<SignInForm>(form, rules);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    if (dirty) {
      touch();
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (touch()) {
      return;
    }

    onSubmit(form);
  };

  return (
    <Box>
      {/* <Typography variant="h2" textAlign="center" marginBottom={4}>
        Sign in to your account
      </Typography> */}

      <Box component="form" onSubmit={handleSubmit}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <InputField
            label="Email"
            value={form.email}
            variant="filled"
            name="email"
            disabled={loading}
            error={dirty && errors.email.dirty}
            helperText={dirty && mappingErrors(errors?.email?.message)}
            type="email"
            onChange={handleChange}
          />

          <InputField
            label="Password"
            value={form.password}
            variant="filled"
            name="password"
            type="password"
            error={dirty && errors.password.dirty}
            helperText={dirty && mappingErrors(errors?.password?.message)}
            disabled={loading}
            onChange={handleChange}
          />
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            margin: '24px 0',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="rememberMe"
                checked={form.rememberMe}
                onChange={handleCheckboxChange}
              />
            }
            label={
              <Typography component="span" variant="body2">
                Remember Me
              </Typography>
            }
          />

          <Link
            component="span"
            variant="body2"
            sx={{
              color: colors.blue,
              fontWeight: 700,
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => setAuthType('resetPassword')}
          >
            Forgot Password?
          </Link>
        </Stack>

        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Sign in
        </Button>
      </Box>

      {/*<Button*/}
      {/*  variant="outlined-primary"*/}
      {/*  disabled={loading}*/}
      {/*  loading={loading}*/}
      {/*  sx={{ marginTop: 1 }}*/}
      {/*  prepend={*/}
      {/*    <Icon*/}
      {/*      width={{ xs: 16, sm: 20 }}*/}
      {/*      height={{ xs: 16, sm: 20 }}*/}
      {/*      color={colors.blue}*/}
      {/*      icon="google"*/}
      {/*    />*/}
      {/*  }*/}
      {/*>*/}
      {/*  Sign in with Google*/}
      {/*</Button>*/}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="8px"
        sx={{
          mt: '32px',
        }}
      >
        <Typography variant="body2">Don’t have an account?</Typography>

        <Link
          component="button"
          variant="body2"
          sx={{
            color: colors.blue,
            fontWeight: 700,
            textDecoration: 'none',
          }}
          onClick={() => setAuthType('signUp')}
        >
          Create
        </Link>
      </Stack>
    </Box>
  );
};

export default SignUp;
