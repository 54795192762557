'use client';
import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';

import InputField from '@/shared/components/InputField';
import ButtonComponent from '@/shared/components/ButtonComponent';

import { CreateNewPasswordForm, onSubmitAuthFormType } from '../types';
import useValidate, {
  Rules,
  passwordPattern,
} from '@/shared/hooks/useValidate.hook';
import mappingErrors from '@/shared/helpers/mappingErrors.helper';

type Props = {
  loading: boolean;
  onSubmit: onSubmitAuthFormType<CreateNewPasswordForm>;
};

const SetNewPassword = (props: Props) => {
  const { loading, onSubmit } = props;

  const [form, setForm] = useState<CreateNewPasswordForm>({
    email: '',
    otp_code: '',
    confirm_new_password: '',
    new_password: '',
  });

  const rules: Rules<CreateNewPasswordForm> = {
    email: {
      required: false,
    },
    otp_code: {
      required: false,
    },
    new_password: {
      required: true,
      minLength: 8,
      maxLength: 50,
      passwordPattern,
      fieldKey: 'New password',
    },
    confirm_new_password: {
      required: true,
      same: 'new_password',
      fieldKey: 'Confirm new password',
    },
  };

  const { dirty, touch, errors } = useValidate<CreateNewPasswordForm>(
    form,
    rules,
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    if (dirty) {
      touch();
    }
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (touch()) {
      return;
    }

    onSubmit(form);
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        Create new password
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={2}
      >
        Password should be more than 8 characters, consist of uppercase and
        lowercase letters and symbols like %, &, $, #...
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <InputField
            label="New password"
            value={form.new_password}
            variant="filled"
            required
            name="new_password"
            type="password"
            error={dirty && errors.new_password.dirty}
            helperText={dirty && mappingErrors(errors?.new_password?.message)}
            disabled={loading}
            onChange={handleChange}
          />
          <InputField
            label="Confirm new password"
            value={form.confirm_new_password}
            variant="filled"
            required
            name="confirm_new_password"
            error={dirty && errors.confirm_new_password.dirty}
            helperText={
              dirty && mappingErrors(errors?.confirm_new_password?.message)
            }
            type="password"
            disabled={loading}
            onChange={handleChange}
          />
        </Box>
        <ButtonComponent
          type="submit"
          disabled={loading}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Set up new password
        </ButtonComponent>
      </Box>
    </Box>
  );
};

export default SetNewPassword;
