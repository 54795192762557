'use client';

import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNotifyStore } from '@/lib/store/notify.store';

export default function Snackbars() {
  const { notify, setNotify } = useNotifyStore(state => state);

  const [open, setOpen] = React.useState<boolean>(!!notify);

  const handleClose = () => {
    setOpen(false);
    const timeout = setTimeout(() => {
      setNotify(null);
      clearTimeout(timeout);
    }, 500);
  };

  React.useEffect(() => {
    setOpen(!!notify);
  }, [notify]);

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        {...(notify && { severity: notify.type })}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {notify && notify.message}
      </Alert>
    </Snackbar>
  );
}
