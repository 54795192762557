'use client';

import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useMediaQuery } from '@mui/material';
import theme from '@/lib/theme/theme';
import { usePathname } from 'next/navigation';

const Logo = () => {
  const isHomePage = usePathname() === '/';

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const logoWidth = isMobile ? 75 : 116;
  const logoHeight = isMobile ? 19 : 28;
  if (isHomePage) {
    return (
      <Image
        src="/logo.svg"
        alt="Inkhub"
        unoptimized
        priority
        width={logoWidth}
        height={logoHeight}
      />
    );
  }
  return (
    <Link href="/" style={{ display: 'block' }}>
      <Image
        src="/logo.svg"
        alt="Inkhub"
        unoptimized
        priority
        width={logoWidth}
        height={logoHeight}
      />
    </Link>
  );
};

export default Logo;
