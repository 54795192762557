import React from 'react';

import { Box, Typography } from '@mui/material';

import Button from '@/shared/components/ButtonComponent';
import { AuthType, onSubmitAuthFormType, ResesOtpCodeForm } from '../types';

type Props = {
  title: string;
  email: string;
  loading: boolean;
  onSubmit: onSubmitAuthFormType<ResesOtpCodeForm>;
  setAuthType: (authType: AuthType) => void;
};

function EmailAlreadyRegistrated(props: Props) {
  const { email, title, loading, setAuthType, onSubmit } = props;

  const handleResendOtpCode = () => {
    onSubmit({
      email,
      action: 'profile_activation',
    });
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        {title}
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={2}
      >
        This <b>{email}</b> email is already registered in our system.
        <br />
        You can resend the confirmation code to this email and activate your
        account.
      </Typography>

      <Button
        variant="filled"
        disabled={loading}
        loading={loading}
        sx={{ marginTop: 1 }}
        onClick={handleResendOtpCode}
      >
        Resend confirmation code
      </Button>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={1}
        marginTop={1}
      >
        or
      </Typography>

      <Button
        variant="outlined-primary"
        disabled={loading}
        loading={loading}
        sx={{ marginTop: 1 }}
        onClick={() => setAuthType('signUp')}
      >
        Create new account
      </Button>
    </Box>
  );
}

export default EmailAlreadyRegistrated;
