import React from 'react';
import {
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  alpha,
  styled,
} from '@mui/material';
import { colors } from '@/lib/theme/palette';

const InputField = styled((props: TextFieldProps & { readonly?: boolean }) => (
  <TextField
    InputProps={
      {
        disableUnderline: true,
        readOnly: props.readonly,
      } as Partial<OutlinedInputProps>
    }
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: colors.white,
    border: '1px solid',
    borderColor: colors.black400,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: 'rgba(155, 168, 183, 1)',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default InputField;
