import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type NotifyType = {
  message: string;
  type: 'error' | 'success' | 'info';
};

export type NotifyStore = {
  notify: NotifyType | null;
  setNotify: (notify: NotifyType | null) => void;
};

export const useNotifyStore = create<NotifyStore>()(
  persist(
    set => ({
      notify: null,
      setNotify: notify => set({ notify }),
    }),
    { name: 'notify-store', skipHydration: false },
  ),
);
