'use client';

import React from 'react';

import SignUp from './SignUp';
import SignIn from './SignIn';
import ResetPassword from './ResetPassword';
import SetNewPassword from './SetNewPassword';
import Confirm from './Confirm';
import EmailNotFound from './EmailNotFound';
import EmailAlreadyRegistrated from './EmailAlreadyRegistrated';

import {
  AuthType,
  GeneralForm,
  onSubmitAuthFormType,
  TriggerFoGeneralFormType,
} from './types';

type Props = {
  authType: AuthType;
  loading: boolean;
  email: string;
  setAuthType: (authType: AuthType) => void;
  onSubmit: onSubmitAuthFormType<GeneralForm>;
  handleClose: () => void;
};

const Auth = (props: Props) => {
  const { authType, loading, email, setAuthType, onSubmit, handleClose } =
    props;

  const handleSubmit = (
    form: GeneralForm,
    trigger?: TriggerFoGeneralFormType,
  ) => {
    onSubmit(form, trigger);
  };

  const params = {
    setAuthType,
    loading,
    onSubmit: handleSubmit,
  };

  switch (authType) {
    case 'signUp':
      return <SignUp {...params} handleClose={handleClose} />;
    case 'signIn':
      return <SignIn {...params} />;
    case 'resetPassword':
      return <ResetPassword {...params} />;
    case 'setNewPassword':
      return <SetNewPassword {...params} />;
    case 'signUpConfirmation':
      return (
        <Confirm
          {...params}
          email={email}
          backToAuthType="signUp"
          title="Confirm registration"
        />
      );
    case 'resetConfirmation':
      return (
        <Confirm
          {...params}
          email={email}
          backToAuthType="resetPassword"
          title="Confirm request"
        />
      );
    case 'emailNotFound':
      return (
        <EmailNotFound
          {...params}
          email={email}
          backToAuthType="resetPassword"
        />
      );
    case 'EmailAlreadyRegistrated':
      return (
        <EmailAlreadyRegistrated
          {...params}
          title="Email already registered"
          email={email}
        />
      );

    case 'ActiveYourAccount':
      return (
        <EmailAlreadyRegistrated
          {...params}
          title="You account is not activeted"
          email={email}
        />
      );
    default:
      return null;
  }
};

export default Auth;
