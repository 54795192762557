'use client';
import React, { useState } from 'react';
import Link from 'next/link';
import {
  Box,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import MuiLink from '@mui/material/Link';

import { colors } from '@/lib/theme/palette';

import InputField from '@/shared/components/InputField';
import Button from '@/shared/components/ButtonComponent';

import { AuthType, onSubmitAuthFormType, SignUpForm } from '../types';

import useValidate, {
  Rules,
  emailPattern,
  passwordPattern,
} from '@/shared/hooks/useValidate.hook';
import mappingErrors from '@/shared/helpers/mappingErrors.helper';
import { replaceMultipleHyphensAndSpaces } from '@/lib/utils';

type Props = {
  loading: boolean;
  onSubmit: onSubmitAuthFormType<SignUpForm>;
  setAuthType: (authType: AuthType) => void;
  handleClose: () => void;
};

const SignUp = (props: Props) => {
  const { loading, setAuthType, onSubmit, handleClose } = props;

  const [form, setForm] = useState<SignUpForm>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    attach_mailing: false,
  });

  const [disabled, setDisabled] = useState(
    Object.values(form).some(value => value === ''),
  );

  const rules: Rules<SignUpForm> = {
    first_name: {
      required: true,
      minLength: 2,
      maxLength: 50,
      text: true,
      fieldKey: 'First Name',
    },
    last_name: {
      required: true,
      minLength: 2,
      maxLength: 50,
      text: true,
      fieldKey: 'Last Name',
    },
    email: {
      required: true,
      emailPattern,
      fieldKey: 'Email',
    },
    password: {
      required: true,
      minLength: 8,
      maxLength: 50,
      passwordPattern,
      fieldKey: 'Password',
    },

    attach_mailing: {
      required: false,
    },
  };

  const { dirty, touch, errors } = useValidate<SignUpForm>(form, rules);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevForm => {
      const newForm = {
        ...prevForm,
        [e.target.name]: e.target.value,
      };
      setDisabled(Object.values(newForm).some(value => value === ''));

      return newForm;
    });

    if (dirty) {
      touch();
    }
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (touch()) {
      return;
    }

    onSubmit({
      ...form,
      first_name: replaceMultipleHyphensAndSpaces(form.first_name),
      last_name: replaceMultipleHyphensAndSpaces(form.last_name),
    });
  };

  const handleCloseModal = () => {
    handleClose && handleClose();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      attach_mailing: e.target.checked,
    });
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center" marginBottom={4}>
        Create an account
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <InputField
            label="First Name"
            value={form.first_name}
            variant="filled"
            name="first_name"
            type="text"
            error={dirty && errors.first_name.dirty}
            helperText={dirty && mappingErrors(errors?.first_name?.message)}
            disabled={loading}
            onChange={handleChange}
          />

          <InputField
            label="Last Name"
            value={form.last_name}
            variant="filled"
            name="last_name"
            type="text"
            error={dirty && errors.last_name.dirty}
            helperText={dirty && mappingErrors(errors.last_name.message)}
            disabled={loading}
            onChange={handleChange}
          />

          <InputField
            label="Email"
            value={form.email}
            variant="filled"
            name="email"
            disabled={loading}
            error={dirty && errors.email.dirty}
            helperText={dirty && mappingErrors(errors.email.message)}
            onChange={handleChange}
          />

          <InputField
            label="Password"
            value={form.password}
            variant="filled"
            name="password"
            type="password"
            error={dirty && errors.password.dirty}
            helperText={dirty && mappingErrors(errors.password.message)}
            disabled={loading}
            onChange={handleChange}
          />
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              name="rememberMe"
              checked={form.attach_mailing}
              onChange={handleCheckboxChange}
            />
          }
          label={
            <Typography component="span" variant="body2">
              Add me to the mailing list
            </Typography>
          }
          sx={{
            margin: '24px 0 0',
            marginLeft: '-11px',
          }}
        />

        <Button
          type="submit"
          disabled={loading || disabled}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Create an account
        </Button>
      </Box>

      <Typography variant="body2" textAlign="center" sx={{ mt: '32px' }}>
        By proceeding, you agree to our &nbsp;
        <Link
          href="/terms-and-conditions"
          style={{ color: colors.blue }}
          onClick={handleCloseModal}
        >
          Terms of Use
        </Link>
        &nbsp; and &nbsp;
        <Link
          href="/privacy-policy"
          style={{ color: colors.blue }}
          onClick={handleCloseModal}
        >
          Privacy Policy
        </Link>
      </Typography>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="8px"
        sx={{
          mt: '32px',
        }}
      >
        <Typography variant="body2">Already have an account?</Typography>

        <MuiLink
          component="button"
          variant="body2"
          sx={{
            color: colors.blue,
            fontWeight: 700,
            textDecoration: 'none',
          }}
          onClick={() => setAuthType('signIn')}
        >
          Sign in
        </MuiLink>
      </Stack>
    </Box>
  );
};

export default SignUp;
