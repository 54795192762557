'use client';
import React from 'react';

import { Box, Typography } from '@mui/material';

import Button from '@/shared/components/ButtonComponent';

import { AuthType } from '../types';
import useQueryParams from '@/shared/hooks/useQueryParams.hook';

type Props = {
  loading: boolean;
  backToAuthType: AuthType;
  email: string;
  setAuthType: (authType: AuthType) => void;
};

const EmailNotFound = (props: Props) => {
  const { loading, setAuthType, backToAuthType } = props;

  const { queries } = useQueryParams<{ email: string }>({
    email: '',
  });

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        Email is not found
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={1}
      >
        Seems like we don’t have any account associated with {queries.email}
      </Typography>

      <Button
        disabled={loading}
        loading={loading}
        sx={{ marginTop: 3 }}
        onClick={() => setAuthType('signUp')}
      >
        Create an account
      </Button>

      <Button
        variant="outlined-primary"
        disabled={loading}
        loading={loading}
        sx={{ marginTop: 1 }}
        onClick={() => setAuthType(backToAuthType)}
      >
        Back to website
      </Button>
    </Box>
  );
};

export default EmailNotFound;
